html {
  align-items: center;
  text-align: center;
  height: 100%;
  background-color: #f0f2f5;
}

body {
  margin: 0;
}
#footer {
  bottom: 0;
  color: #707070;
  height: 2em;
  left: 0;
  position: relative;
  font-size: small;
  width: 100%;
}
