.changepassword-form {
    background-color: aliceblue;
    margin: auto;
    width: 30%;
    height: 500px;
    border-radius: 8px;
    box-shadow: 5px 10px 18px #888888;
    margin-top: 50px;
  }
  
  .changepassword-title {
    font-size: 45px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid #888888;
    margin-bottom: 10px;
  }
  
 .changepassword-label {
    float: left;
    /* padding-top: 10px; */
    padding-left: 40px;
    font-size: 20px;
  }
  
 .changepassword-form input {
    width: 80%;
    font-size: 18px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #888888;
    margin-bottom: 20px;
  }
  
  .changepassword-btn {
    box-shadow: 0px 10px 20px -10px #606e70;
    background-color: #30b6e3;
    border-radius: 10px;
    border: 3px solid #c1d3de;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: "Recursive", sans-serif;
    font-size: 30px;
    padding: 8px 20px;
    text-decoration: none;
    margin-top: 15px;
  }
  
  .changepassword-btn:hover {
    background-color: #a4c7ed;
  }
  .changepassword-btn:active {
    position: relative;
    top: 5px;
  } 